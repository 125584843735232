import { RoleType } from "./enums";
import { getToken } from "./storageActions";

class IUser {
    userId!: number;
    username!: string;
    merchantId!: number;
    permissions!: string[];
    employeeRole!: RoleType;
    contractIds!: number[];
    name!: string;
}

export const getUser = (): IUser => {
    const tokenJwt = getToken();

    let tokenData = parseJwt(tokenJwt);

    // object.assign will work with flat models, e.g. if IUser has some nested object inside it won't be able to deserialize to IUser
    // if we need support for nested models inside IUser we will need to change this method
    const user: IUser = Object.assign(new IUser(), parseJwt(tokenJwt));

    // if it's only one claim in token it will be converted as string and that causes problem for permissions where we are expecting a array, so we will convert it to string[]
    if (tokenData.permissions === undefined) {
        user.permissions = [];
    } else if (typeof tokenData.permissions == "string") {
        user.permissions = [tokenData.permissions];
    }

    return user;
};

export function parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}
