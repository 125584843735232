import classNames from "classnames";
import TableCellCurrency from "components/tableCellComponents/tableCellCurrency/tableCellCurrency";
import { t } from "i18next";
import { IGetSettlementsResponseListData } from "pages/settlements/api/settlementsModels";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { propOf } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import "./settlementsBreakdown.scss";
import { ITransactionsFilter } from "pages/transactions/api/transactionModels";
import { setDrillFilters } from "redux/reducers/reportReducer";
import { nameof } from "ts-simple-nameof";
import { PATHS } from "utils/routing/paths";
import { useNavigate } from "react-router-dom";

const SettlementsBreakdown = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeRowData: IGetSettlementsResponseListData = useSelector((state: RootState) => state.report.activeRow);

    if (!activeRowData) {
        return <></>;
    }

    const rows = [
        {
            title: t(StringResources.pages.settlements.grossPurchase),
            prop: propOf<IGetSettlementsResponseListData>(x => x.grossPurchase),
            important: true,
        },
        {
            title: t(StringResources.pages.settlements.refundAmount),
            prop: propOf<IGetSettlementsResponseListData>(x => x.refundAmount),
            important: true,
        },
        {
            title: t(StringResources.pages.settlements.reversals),
            prop: propOf<IGetSettlementsResponseListData>(x => x.reversals),
            important: true,
        },
        {
            title: t(StringResources.pages.settlements.grossSettlement),
            prop: propOf<IGetSettlementsResponseListData>(x => x.grossSettlement),
            important: true,
            dashedBorderTop: true,
        },
        {
            title: t(StringResources.pages.settlements.feeAmount),
            prop: propOf<IGetSettlementsResponseListData>(x => x.feeAmount),
            important: true,
        },
        {
            title: t(StringResources.pages.settlements.transactionFee),
            prop: propOf<IGetSettlementsResponseListData>(x => x.transactionFee),
            indent: true,
            noRounding: true,
        },
        {
            title: t(StringResources.pages.settlements.joiningFee),
            prop: propOf<IGetSettlementsResponseListData>(x => x.joiningFee),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.chargebackFees),
            prop: propOf<IGetSettlementsResponseListData>(x => x.chargebackFees),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.swiftFees),
            prop: propOf<IGetSettlementsResponseListData>(x => x.swiftFees),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.rejected),
            prop: propOf<IGetSettlementsResponseListData>(x => x.rejected),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.chargeback),
            prop: propOf<IGetSettlementsResponseListData>(x => x.chargeback),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.representment),
            prop: propOf<IGetSettlementsResponseListData>(x => x.representment),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.posRentalFee),
            prop: propOf<IGetSettlementsResponseListData>(x => x.posRentalFee),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.preArbitration),
            prop: propOf<IGetSettlementsResponseListData>(x => x.preArbitration),
            indent: true,
        },
        {
            title: t(StringResources.pages.settlements.netSettlement),
            prop: propOf<IGetSettlementsResponseListData>(x => x.netSettlement),
            important: true,
            dashedBorderTop: true,
        },
    ];

    const onRedirectClickWithDrill = async () => {
        await dispatch(
            setDrillFilters([
                {
                    field: nameof<ITransactionsFilter>(x => x.settlementNumber),
                    value: (activeRowData as IGetSettlementsResponseListData).settlementNumber,
                },
            ])
        );

        navigate(PATHS.Portal.Transactions);
    };

    return (
        <div className="settlement-breakdown">
            <div className="settlement-breakdown__header">
                <div className="settlement-breakdown__title">{`${t(StringResources.pages.settlements.breakdown)}`}</div>
                <div className="settlement-breakdown__link" onClick={onRedirectClickWithDrill}>
                    {`${t(StringResources.pages.settlements.viewTransactions)}`}
                </div>
            </div>
            <div className="settlement-breakdown__body">
                {rows.map((row, index) => {
                    if (!row.important && activeRowData[row.prop] == 0) {
                        return <React.Fragment key={index}></React.Fragment>;
                    }

                    return (
                        <div
                            key={index}
                            className={classNames("settlement-breakdown__row", {
                                "dashed-border-top": row.dashedBorderTop,
                            })}
                        >
                            <div
                                className={classNames("settlement-breakdown__row--item-label", {
                                    important: row.important,
                                    indent: row.indent,
                                    "last-child": rows.length === index + 1,
                                })}
                            >
                                {row.title}
                            </div>
                            <div
                                className={classNames("settlement-breakdown__row--item-value", {
                                    indent: row.indent,
                                    "last-child": rows.length === index + 1,
                                })}
                            >
                                {TableCellCurrency({
                                    value: +activeRowData[row.prop]!,
                                    format: activeRowData.currencyId,
                                    options: {
                                        formatOptions: activeRowData.currencyId === "ISK" ? "Symbol" : "Code",
                                        specialRules: row.noRounding ? ["NoRounding"] : [],
                                    },
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SettlementsBreakdown;
