import { FC } from "react";
import Select, { OnChangeValue, StylesConfig, components } from "react-select";
import "./baseSelect.scss";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { AngleIcon } from "components/icons";
import variables from "../../style/colors.module.scss";

export interface Option {
    value: number | string;
    label: string;
    selected: boolean;
}

interface IBaseSelectProps {
    options: Array<Option>;
    label?: React.ReactNode;
    isDisabled?: boolean;
    isClearable?: boolean;
    onChange: (selectedItem: Option) => void;
}

const BaseSelect: FC<IBaseSelectProps> = ({ label, isDisabled = false, isClearable = false, options, onChange }) => {
    const handleChange = (selectedItem: OnChangeValue<Option, boolean>) => {
        // we are casting to Option because we will always use this component as SingleSelect so it will return selected option
        onChange(selectedItem as Option);
    };

    return (
        <BaseInputWrapper label={label} value={options.filter(x => x.selected).map(x => x.label)}>
            <div className="select-wrapper">
                <Select
                    styles={customStyles}
                    className="form-control"
                    closeMenuOnSelect={true}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    isMulti={false}
                    placeholder={""}
                    options={options}
                    value={options.find(x => x.selected) ?? null}
                    onChange={handleChange}
                    menuPlacement="auto"
                    menuPosition={"fixed"}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => (
                            <AngleIcon height={14} width={10} className="select-wrapper__toggle-icon" />
                        ),
                        ClearIndicator: (props: any) => (
                            <div
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onMouseDown={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <components.ClearIndicator {...props} />
                            </div>
                        ),
                    }}
                />
            </div>
        </BaseInputWrapper>
    );
};

// programatically style for react-select component
const customStyles: StylesConfig<Option, boolean> = {
    control: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isDisabled ? variables.colorGrayEpsilon : variables.colorTransparent,
            border: "0",
            boxShadow: "none",
            height: "100%",
            padding: "7px",
        };
    },
    option: (styles, state) => {
        return {
            ...styles,
            display: "flex",
            cursor: state.isDisabled ? "not-allowed" : "default",
            marginTop: "4px",
            marginBottom: "4px",
            padding: "12px 16px",
            fontSize: "1rem",
            lineHeight: 1.5,
            borderRadius: "4px",
            backgroundColor: state.isDisabled
                ? undefined
                : state.isSelected
                ? variables.colorBlueGamma
                : state.isFocused
                ? variables.colorGrayBeta
                : undefined,
            color: state.isDisabled ? undefined : state.isSelected ? variables.colorSecondaryDelta : undefined,
            ":active": {
                ...styles[":active"],
                backgroundColor: variables.colorGrayBeta,
            },
        };
    },
    menuList: styles => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: "4px",
        marginRight: "4px",
    }),
    menu: styles => ({ ...styles, zIndex: 500 }),
    menuPortal: styles => ({ ...styles, zIndex: 500 }),
    clearIndicator: styles => ({
        ...styles,
        paddingRight: "16px",
        color: variables.colorPrimaryAlpha,
        ":hover": { ...styles[":hover"], color: variables.colorPrimaryAlpha, cursor: "pointer" },
    }),
};

export default BaseSelect;
