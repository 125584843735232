import { PageTypeEnum } from "utils/enums";

export const StringResources = {
    modal: {
        close: "modal.close",
        confirm: "modal.confirm",
        delete: "modal.delete",
        cancel: "modal.cancel",
        set: "modal.set",
        details: "modal.details",
        submit: "modal.submit",
        feedback: {
            title: "modal.feedback.title",
            label: "modal.feedback.label",
            placeholder: "modal.feedback.placeholder",
            success: "modal.feedback.success",
            error: "modal.feedback.error",
            message: "modal.feedback.message",
        },
        column: "modal.column",
        filter: "modal.filter",
        deleteModal: {
            title: "modal.deleteModal.title",
            message: "modal.deleteModal.message",
        },
        refundTransaction: {
            title: "modal.refundTransaction.title",
            message: "modal.refundTransaction.message",
            refund: "modal.refundTransaction.refund",
            fullRefund: "modal.refundTransaction.fullRefund",
            partialRefund: "modal.refundTransaction.partialRefund",
            formRefundAmount: "modal.refundTransaction.formRefundAmount",
            formRefundAmountRequired: "modal.refundTransaction.formRefundAmountRequired",
            formRefundAmountMin: "modal.refundTransaction.formRefundAmountMin",
            formRefundAmountMax: "modal.refundTransaction.formRefundAmountMax",
            maxAmountForRefund: "modal.refundTransaction.maxAmountForRefund",
            amountForRefund: "modal.refundTransaction.amountForRefund",
            success: "modal.refundTransaction.success",
            downloadSuccess: "modal.refundTransaction.downloadSuccess",
            downloadError: "modal.refundTransaction.downloadError",
            error: "modal.refundTransaction.error",
            confirmRefund: "modal.refundTransaction.confirmRefund",
            confirmRefundMessage: "modal.refundTransaction.confirmRefundMessage",
            downloadRefundReceipt: "modal.refundTransaction.downloadRefundReceipt",
        },
        reversalAuthorization: {
            reversal: "modal.reversalAuthorization.reversal",
            confirmReversalMessage: "modal.reversalAuthorization.confirmReversalMessage",
            success: "modal.reversalAuthorization.success",
            error: "modal.reversalAuthorization.error",
        },
        disputeDetails: {
            respond: "modal.disputeDetails.respond",
            acceptDispute: "modal.disputeDetails.acceptDispute",
            originalTransaction: "modal.disputeDetails.originalTransaction",
            cardNumber: "modal.disputeDetails.cardNumber",
            amount: "modal.disputeDetails.amount",
            purchaseDate: "modal.disputeDetails.purchaseDate",
            authorizationNumber: "modal.disputeDetails.authorizationNumber",
            currency: "modal.disputeDetails.currency",
            originalBatchId: "modal.disputeDetails.originalBatchId",
            settlement: "modal.disputeDetails.settlement",
            dbaName: "modal.disputeDetails.dbaName",
            agreement: "modal.disputeDetails.agreement",
            transactionNumber: "modal.disputeDetails.transactionNumber",
            arn: "modal.disputeDetails.arn",
            originalTransactionDate: "modal.disputeDetails.originalTransactionDate",
            transactionCode: "modal.disputeDetails.transactionCode",
            transactionType: "modal.disputeDetails.transactionType",
            response: "modal.disputeDetails.response",
            date: "modal.disputeDetails.date",
            disputeEmailAddress: "modal.disputeDetails.disputeEmailAddress",
            attachement: "modal.disputeDetails.attachement",
            responseText: "modal.disputeDetails.responseText",
            case: "modal.disputeDetails.case",
            representment: "modal.disputeDetails.representment",
            chargeback: "modal.disputeDetails.chargeback",
            getError: "modal.disputeDetails.getError",
            acceptError: "modal.disputeDetails.acceptError",
            acceptSuccess: "modal.disputeDetails.acceptSuccess",
            noData: "modal.disputeDetails.noData",
            reasonCode: "modal.disputeDetails.reasonCode",
            reasonCodeCategory: "modal.disputeDetails.reasonCodeCategory",
            reasonCodeDescription: "modal.disputeDetails.reasonCodeDescription",
            username: "modal.disputeDetails.username",
            reversal: "modal.disputeDetails.reversal",
        },
    },
    errorBoundary: {
        title: "errorBoundary.title",
        subtitle: "errorBoundary.subtitle",
        btnText: "errorBoundary.btnText",
    },
    mainNavigation: {
        dashboard: "mainNavigation.dashboard",
        payments: "mainNavigation.payments",
        terminals: "mainNavigation.terminals",
        agreements: "mainNavigation.agreements",
        dailyBalance: "mainNavigation.dailyBalance",
        settlements: "mainNavigation.settlements",
        batches: "mainNavigation.batches",
        transactions: "mainNavigation.transactions",
        authorizations: "mainNavigation.authorizations",
        exceptions: "mainNavigation.exceptions",
        users: "mainNavigation.users",
        electronicDocuments: "mainNavigation.electronicDocuments",
        notifications: "mainNavigation.notifications",
        settings: "mainNavigation.settings",
        logout: "mainNavigation.logout",
        termsOfService: "mainNavigation.termsOfService",
    },
    dashboard: {
        totalSales: "dashboard.totalSales",
        totalSalesTooltip: "dashboard.totalSalesTooltip",
        salesToday: "dashboard.salesToday",
        salesTodayTooltip: "dashboard.salesTodayTooltip",
        upcomingPayment: "dashboard.upcomingPayment",
        upcomingPaymentTooltip: "dashboard.upcomingPaymentTooltip",
        unpaidSettlementsAmountToolTip: "dashboard.unpaidSettlementsAmountToolTip",
        numberOfSales: "dashboard.numberOfSales",
        numberOfSalesTooltip: "dashboard.numberOfSalesTooltip",
        grossSales: "dashboard.grossSales",
        grossSalesTooltip: "dashboard.grossSalesTooltip",
        online: "dashboard.online",
        offline: "dashboard.offline",
        pieChartName: "dashboard.pieChartName",
        pieChartTooltip: "dashboard.pieChartTooltip",
        salesByOutlet: "dashboard.salesByOutlet",
        salesByOutletTooltip: "dashboard.salesByOutletTooltip",
        salesByOutletBarTooltip: "dashboard.salesByOutletBarTooltip",
        dailyAverage: "dashboard.dailyAverage",
        noDailyAverage: "dashboard.noDailyAverage",
        ofMonthlyTarget: "dashboard.ofMonthlyTarget",
        setSalesTarget: "dashboard.setSalesTarget",
        scheduled: "dashboard.scheduled",
        day: "dashboard.day",
        noData: "dashboard.noData",
        filterByCurrencyPt1: "dashboard.filterByCurrencyPt1",
        filterByCurrencyPt2: "dashboard.filterByCurrencyPt2",
        exportGraph: "dashboard.exportGraph",
        dccCommission: "dashboard.dccCommission",
        dccCommissionTooltip: "dashboard.dccCommissionTooltip",
        dccCommissionTransactions: "dashboard.dccCommissionTransactions",
        outlet: "dashboard.outlet",
        january: "dashboard.january",
        february: "dashboard.february",
        march: "dashboard.march",
        april: "dashboard.april",
        may: "dashboard.may",
        june: "dashboard.june",
        july: "dashboard.july",
        august: "dashboard.august",
        september: "dashboard.september",
        october: "dashboard.october",
        november: "dashboard.november",
        december: "dashboard.december",
        average: "dashboard.average",
        allInCurrency: "dashboard.allInCurrency",
        contracts: "dashboard.contracts",
        unpaidSettlementsAmount: "dashboard.unpaidSettlementsAmount",
    },
    userMenu: {
        back: "userMenu.back",
    },
    filter: {
        totalSubtotal: "filter.totalSubtotal",
        clear: "filter.clear",
        search: "filter.search",
        dropdownSearchPlaceholder: "filter.dropdownSearchPlaceholder",
        currency: "filter.currency",
        buttonText: "filter.buttonText",
    },
    columnChooser: {
        column: "columnChooser.column",
        unsavedChanges: "columnChooser.unsavedChanges",
        save: "columnChooser.save",
        cancel: "columnChooser.cancel",
        selectAll: "columnChooser.selectAll",
        defaultView: "columnChooser.defaultView",
    },
    reportDetails: {
        boolean: {
            yesLabel: "reportDetails.boolean.yesLabel",
            noLabel: "reportDetails.boolean.noLabel",
        },
        nonFinancial: "reportDetails.nonFinancial",
    },
    table: {
        cell: {
            boolean: {
                yesLabel: "table.cell.boolean.yesLabel",
                noLabel: "table.cell.boolean.noLabel",
            },
        },
        fallbackMessage: {
            default: "table.fallbackMessage.default",
            [PageTypeEnum.Agreements]: "table.fallbackMessage.agreements",
            [PageTypeEnum.Batches]: "table.fallbackMessage.batches",
            [PageTypeEnum.Payments]: "table.fallbackMessage.payments",
            [PageTypeEnum.Settlements]: "table.fallbackMessage.settlements",
            [PageTypeEnum.Transactions]: "table.fallbackMessage.transactions",
            [PageTypeEnum.Authorizations]: "table.fallbackMessage.authorizations",
            [PageTypeEnum.Disputes]: "table.fallbackMessage.disputes",
            [PageTypeEnum.DisputeNew]: "table.fallbackMessage.disputes",
            [PageTypeEnum.Employees]: "table.fallbackMessage.users",
            [PageTypeEnum.ElectronicDocuments]: "table.fallbackMessage.electronicDocuments",
            [PageTypeEnum.DailyBalance]: "table.fallbackMessage.dailyBalance",
            [PageTypeEnum.Terminals]: "table.fallbackMessage.terminals",
            [PageTypeEnum.None]: "table.fallbackMessage.default",
            [PageTypeEnum.Dashboard]: "table.fallbackMessage.default",
            [PageTypeEnum.NotFound]: "table.fallbackMessage.default",
        },
        fallbackMessageWithFilter: "table.fallbackMessageWithFilter",
        itemsPerPage: "table.itemsPerPage",
    },
    report: {
        export: "report.export",
        print: "report.print",
        download: "report.download",
    },
    toolTip: {
        [PageTypeEnum.Batches]: {
            searchBy: "toolTip.batches.searchBy",
            batchId: "toolTip.batches.batchId",
            settlementNumber: "toolTip.batches.settlementNumber",
            processorId: "toolTip.batches.processorId",
            terminalId: "toolTip.batches.terminalId",
        },
        [PageTypeEnum.Payments]: {
            searchBy: "toolTip.payments.searchBy",
            agreementNumber: "toolTip.payments.agreementNumber",
            dbaNames: "toolTip.payments.dbaNames",
            settlements: "toolTip.payments.settlements",
        },
        [PageTypeEnum.Settlements]: {
            searchBy: "toolTip.settlements.searchBy",
            settlementNumber: "toolTip.settlements.settlementNumber",
            agreementId: "toolTip.settlements.agreementId",
            dbaNames: "toolTip.settlements.dbaNames",
        },
        [PageTypeEnum.Transactions]: {
            searchBy: "toolTip.transactions.searchBy",
            transactionId: "toolTip.transactions.transactionId",
            authNumber: "toolTip.transactions.authNumber",
            arn: "toolTip.transactions.arn",
            terminalId: "toolTip.transactions.terminalId",
            physicalTerminalId: "toolTip.transactions.physicalTerminalId",
            batchNumber: "toolTip.transactions.batchNumber",
            settlementNumber: "toolTip.transactions.settlementNumber",
            agreementId: "toolTip.transactions.agreementId",
            partnerId: "toolTip.transactions.partnerId",
            externalTransactionId: "toolTip.transactions.externalTransactionId",
        },
    },
    pages: {
        [PageTypeEnum.Agreements]: {
            title: "pages.agreements.title",
            viewSettlements: "pages.agreements.viewSettlements",
            merchantName: "pages.agreements.merchantName",
            agreementNumber: "pages.agreements.agreementNumber",
            createdTime: "pages.agreements.createdTime",
            cardPresent: "merchant.agreements.cardPresent",
            cardNotPresent: "merchant.agreements.cardNotPresent",
            type: "pages.agreements.type",
            status: "pages.agreements.status",
            currency: "pages.agreements.currency",
            currencyCode: "pages.agreements.currencyCode",
            netBalance: "pages.agreements.netBalance",
            grossBalance: "pages.agreements.grossBalance",
            grossBalanceToolTip: "pages.agreements.grossBalanceToolTip",
            merchantNumber: "pages.agreements.merchantNumber",
            merchantRegistrationNumber: "pages.agreements.merchantRegistrationNumber",
            isCardPresent: "pages.agreements.isCardPresent",
            settlements: "pages.agreements.settlements",
        },
        [PageTypeEnum.Authorizations]: {
            title: "pages.authorizations.title",
            search: "pages.authorizations.search",
            dateFrom: "pages.authorizations.dateFrom",
            dateTo: "pages.authorizations.dateTo",
            amountFrom: "pages.authorizations.amountFrom",
            amountTo: "pages.authorizations.amountTo",
            cardNumber: "pages.authorizations.cardNumber",
            authorizationId: "pages.authorizations.authorizationId",
            agreementId: "pages.authorizations.agreementId",
            terminalId: "pages.authorizations.terminalId",
            authorizationCode: "pages.authorizations.authorizationCode",
            agreementNumber: "pages.authorizations.agreementNumber",
            lifeCycleId: "pages.authorizations.lifeCycleId",
            amount: "pages.authorizations.amount",
            state: "pages.authorizations.state",
            par: "pages.authorizations.par",
            authorizationDateTime: "pages.authorizations.authorizationDateTime",
            transactionId: "pages.authorizations.transactionId",
            authorizationStatus: "pages.authorizations.authorizationStatus",
            responseCode: "pages.authorizations.responseCode",
            dbaName: "pages.authorizations.dbaName",
            deviceId: "pages.authorizations.deviceId",
            isRefund: "pages.authorizations.isRefund",
            scheme: "pages.authorizations.scheme",
            viewTransactions: "pages.authorizations.viewTransactions",
            merchantName: "pages.authorizations.merchantName",
            details: {
                title: "pages.authorizations.details.title",
                reverse: "pages.authorizations.reverse",
            },
            additionalData: "pages.authorizations.additionalData",
        },
        [PageTypeEnum.Batches]: {
            title: "pages.batches.title",
            viewTransactions: "pages.batches.viewTransactions",
            feesAndAdditions: "pages.batches.feesAndAdditions",
            serviceCharges: "pages.batches.serviceCharges",
            refund: "pages.batches.refund",
            search: "pages.batches.search",
            allAgreements: "pages.batches.allAgreements",
            status: "pages.batches.status",
            dateFrom: "pages.batches.dateFrom",
            dateTo: "pages.batches.dateTo",
            amountFrom: "pages.batches.amountFrom",
            amountTo: "pages.batches.amountTo",
            settledFrom: "pages.batches.settledFrom",
            settledTo: "pages.batches.settledTo",
            paidFrom: "pages.batches.paidFrom",
            paidTo: "pages.batches.paidTo",
            settlementType: "pages.batches.settlementType",
            agreementId: "pages.batches.agreementId",
            dbaName: "pages.batches.dbaName",
            date: "pages.batches.date",
            batch: "pages.batches.batch",
            batchTotal: "pages.batches.batchTotal",
            transactions: "pages.batches.transactions",
            settlementNumber: "pages.batches.settlementNumber",
            processorId: "pages.batches.processorId",
            chargeback: "pages.batches.chargeback",
            currency: "pages.batches.currency",
            gross: "pages.batches.gross",
            netAmount: "pages.batches.netAmount",
            paid: "pages.batches.paid",
            partnerId: "pages.batches.partnerId",
            settled: "pages.batches.settled",
            terminalId: "pages.batches.terminalId",
            details: {
                grossPurchase: "pages.batches.details.grossPurchase",
                refundAmount: "pages.batches.details.refundAmount",
                reversals: "pages.batches.details.reversals",
                grossBatch: "pages.batches.details.grossBatch",
                feeAmount: "pages.batches.details.feeAmount",
                transactionFee: "pages.batches.details.transactionFee",
                joiningFee: "pages.batches.details.joiningFee",
                chargebackFees: "pages.batches.details.chargebackFees",
                swiftFees: "pages.batches.details.swiftFees",
                rejected: "pages.batches.details.rejected",
                representment: "pages.batches.details.representment",
                posRentalFee: "pages.batches.details.posRentalFee",
                preArbitration: "pages.batches.details.preArbitration",
                netBatch: "pages.batches.details.netBatch",
                breakdown: "pages.batches.details.breakdown",
                brandType: "pages.batches.details.brandType",
                transactions: "pages.batches.details.transactions",
                viewSettlement: "pages.batches.details.viewSettlement",
            },
        },
        [PageTypeEnum.DailyBalance]: {
            title: "pages.dailyBalance.title",
            agreementNumber: "pages.dailyBalance.agreementNumber",
            dateFrom: "pages.dailyBalance.dateFrom",
            dateTo: "pages.dailyBalance.dateTo",
            merchantNumber: "pages.dailyBalance.merchantNumber",
            partnerId: "pages.dailyBalance.partnerId",
            currency: "pages.dailyBalance.currency",
            date: "pages.dailyBalance.date",
            netBalance: "pages.dailyBalance.netBalance",
            netBalanceTooltip: "pages.dailyBalance.netBalanceTooltip",
            grossBalance: "pages.dailyBalance.grossBalance",
            grossBalanceTooltip: "pages.dailyBalance.grossBalanceTooltip",
            paidAmount: "pages.dailyBalance.paidAmount",
            paidAmountTooltip: "pages.dailyBalance.paidAmountTooltip",
        },
        [PageTypeEnum.Dashboard]: {
            title: "pages.dashboard.title",
            date: "pages.dashboard.date",
            setSalesTarget: "pages.dashboard.setSalesTarget",
            targetAmount: "pages.dashboard.targetAmount",
        },
        [PageTypeEnum.ElectronicDocuments]: {
            title: "pages.electronicDocuments.title",
            date: "pages.electronicDocuments.date",
            documentName: "pages.electronicDocuments.documentName",
            dateCreated: "pages.electronicDocuments.dateCreated",
            documentType: "pages.electronicDocuments.documentType",
            agreementNumber: "pages.electronicDocuments.agreementNumber",
            error: "pages.electronicDocuments.error",
            ssn: "pages.electronicDocuments.ssn",
            authorizationDateTime: "pages.electronicDocuments.authorizationDateTime",
            amount: "pages.electronicDocuments.amount",
            dbaName: "pages.electronicDocuments.dbaName",
            cardNumber: "pages.electronicDocuments.cardNumber",
            printStarted: "pages.electronicDocuments.printStarted",
            details: {
                download: "pages.electronicDocuments.details.download",
                fileDownloadError: "pages.electronicDocuments.details.fileDownloadError",
                fileDownloadSuccess: "pages.electronicDocuments.details.fileDownloadSuccess",
            },
        },
        [PageTypeEnum.Payments]: {
            title: "pages.payments.title",
            merchantName: "pages.payments.merchantName",
            breakdown: "pages.payments.breakdown",
            breakdownNoRecords: "pages.payments.breakdownNoRecords",
            settlement: "pages.payments.settlement",
            netPurchase: "pages.payments.netPurchase",
            payout: "pages.payments.payout",
            search: "pages.payments.search",
            dateFrom: "pages.payments.dateFrom",
            dateTo: "pages.payments.dateTo",
            amountFrom: "pages.payments.amountFrom",
            amountTo: "pages.payments.amountTo",
            agreementNumber: "pages.payments.agreementNumber",
            currency: "pages.payments.currency",
            swift: "pages.payments.swift",
            iban: "pages.payments.iban",
            payoutAmount: "pages.payments.payoutAmount",
            feeAmounts: "pages.payments.feeAmounts",
            grossAmounts: "pages.payments.grossAmounts",
            paidDate: "pages.payments.paidDate",
            scheduled: "pages.payments.scheduled",
            partnerId: "pages.payments.partnerId",
            partnerName: "pages.payments.partnerName",
            dbaNames: "pages.payments.dbaNames",
            ssn: "pages.payments.ssn",
            settlementType: "pages.payments.settlementType",
            settlements: "pages.payments.settlements",
            printReportError: "pages.payments.printReportError",
            printSelected: "pages.payments.printSelected",
            printStarted: "pages.payments.printStarted",
        },
        [PageTypeEnum.Settlements]: {
            title: "pages.settlements.title",
            breakdown: "pages.settlements.breakdown",
            search: "pages.settlements.search",
            paidDateFrom: "pages.settlements.paidDateFrom",
            paidDateTo: "pages.settlements.paidDateTo",
            createdDateFrom: "pages.settlements.createdDateFrom",
            createdDateTo: "pages.settlements.createdDateTo",
            amountFrom: "pages.settlements.amountFrom",
            amountTo: "pages.settlements.amountTo",
            allAgreements: "pages.settlements.allAgreements",
            currency: "pages.settlements.currency",
            grossPurchase: "pages.settlements.grossPurchase",
            grossSettlement: "pages.settlements.grossSettlement",
            feeAmount: "pages.settlements.feeAmount",
            totalFees: "pages.settlements.totalFees",
            netSettlement: "pages.settlements.netSettlement",
            batches: "pages.settlements.batches",
            batchNoRecords: "pages.settlements.batchNoRecords",
            viewBatches: "pages.settlements.viewBatches",
            viewTransactions: "pages.settlements.viewTransactions",
            dbaName: "pages.settlements.dbaName",
            gross: "pages.settlements.gross",
            netPurchase: "pages.settlements.netPurchase",
            date: "pages.settlements.date",
            batch: "pages.settlements.batch",
            batchTotal: "pages.settlements.batchTotal",
            batchNumber: "pages.settlements.batchNumber",
            status: "pages.settlements.status",
            settlementFee: "pages.settlements.settlementFee",
            paid: "pages.settlements.paid",
            agreementId: "pages.settlements.agreementId",
            settlementType: "pages.settlements.settlementType",
            refundAmount: "pages.settlements.refundAmount",
            createdDate: "pages.settlements.createdDate",
            scheduledDate: "pages.settlements.scheduledDate",
            settlementNumber: "pages.settlements.settlementNumber",
            batchesCount: "pages.settlements.batchesCount",
            transactionsCount: "pages.settlements.transactionsCount",
            cumulativeFees: "pages.settlements.cumulativeFees",
            payout: "pages.settlements.payout",
            partnerId: "pages.settlements.partnerId",
            partnerName: "pages.settlements.partnerName",
            deduction: "pages.settlements.deduction",
            representment: "pages.settlements.representment",
            chargeback: "pages.settlements.chargeback",
            rollingReserve: "pages.settlements.rollingReserve",
            rollingRelease: "pages.settlements.rollingRelease",
            chargebackFees: "pages.settlements.chargebackFees",
            swiftFees: "pages.settlements.swiftFees",
            transactionFee: "pages.settlements.transactionFee",
            joiningFee: "pages.settlements.joiningFee",
            rejected: "pages.settlements.rejected",
            addedSum: "pages.settlements.addedSum",
            deductedSum: "pages.settlements.deductedSum",
            reversals: "pages.settlements.reversals",
            preArbitration: "pages.settlements.preArbitration",
            posRentalFee: "pages.settlements.posRentalFee",
            exceptionCorrection: "pages.settlements.exceptionCorrection",
            dccCommission: "pages.settlements.dccCommission",
            dbaNames: "pages.settlements.dbaNames",
            ssn: "pages.settlements.ssn",
            claim: "pages.settlements.claim",
            claimDueDate: "pages.settlements.claimDueDate",
            aukakronurFee: "pages.settlements.aukakronurFee",
            otherFees: "pages.settlements.otherFees",
            gomobileFee: "pages.settlements.gomobileFee",
            gomobileInitialFee: "pages.settlements.gomobileInitialFee",
            preAuthorizationFee: "pages.settlements.preAuthorizationFee",
            monthlyFee: "pages.settlements.monthlyFee",
            authorizationFee: "pages.settlements.authorizationFee",
            minimumMonthlyServiceFee: "pages.settlements.minimumMonthlyServiceFee",
            cardNotPresentFee: "pages.settlements.cardNotPresentFee",
            pciFee: "pages.settlements.pciFee",
            mobileAirTimeFee: "pages.settlements.mobileAirTimeFee",
            ecomGatewayMonthlyFee: "pages.settlements.ecomGatewayMonthlyFee",
            ecomGatewayTransactionFee: "pages.settlements.ecomGatewayTransactionFee",
            merchantName: "pages.settlements.merchantName",
            print: "pages.settlements.print",
            printReportError: "pages.settlements.printReportError",
            viewPayment: "pages.settlements.viewPayment",
            hasInvoice: "pages.settlements.hasInvoice",
        },
        [PageTypeEnum.Transactions]: {
            title: "pages.transactions.title",
            search: "pages.transactions.search",
            period: "pages.transactions.period",
            purchaseDateFrom: "pages.transactions.purchaseDateFrom",
            purchaseDateTo: "pages.transactions.purchaseDateTo",
            amountFrom: "pages.transactions.amountFrom",
            amountTo: "pages.transactions.amountTo",
            cardNumber: "pages.transactions.cardNumber",
            settlementNumber: "pages.transactions.settlementNumber",
            cardType: "pages.transactions.cardType",
            cardIssuer: "pages.transactions.cardIssuer",
            registrationDateFrom: "pages.transactions.registrationDateFrom",
            registrationDateTo: "pages.transactions.registrationDateTo",
            paidDateFrom: "pages.transactions.paidDateFrom",
            paidDateTo: "pages.transactions.paidDateTo",
            settlementType: "pages.transactions.settlementType",
            transactionId: "pages.transactions.transactionId",
            isFinancial: "pages.transactions.isFinancial",
            authNumber: "pages.transactions.authNumber",
            transactionCode: "pages.transactions.transactionCode",
            authorizationDate: "pages.transactions.authorizationDate",
            merchant: "pages.transactions.merchant",
            dbaName: "pages.transactions.dbaName",
            referenceData: "pages.transactions.referenceData",
            externalTransactionId: "pages.transactions.externalTransactionId",
            purchaseDate: "pages.transactions.purchaseDate",
            originalAmount: "pages.transactions.originalAmount",
            gross: "pages.transactions.gross",
            netAmount: "pages.transactions.netAmount",
            schemeFee: "pages.transactions.schemeFee",
            schemeFeeCurrency: "pages.transactions.schemeFeeCurrency",
            currency: "pages.transactions.currency",
            secure3D: "pages.transactions.secure3D",
            batch: "pages.transactions.batch",
            registrationNumber: "pages.transactions.registrationNumber",
            reasonCode: "pages.transactions.reasonCode",
            cardholderAmount: "pages.transactions.cardholderAmount",
            cardholderCurency: "pages.transactions.cardholderCurency",
            cashbackAmount: "pages.transactions.cashbackAmount",
            agreementId: "pages.transactions.agreementId",
            merchantBucket: "pages.transactions.merchantBucket",
            physicalTermianlId: "pages.transactions.physicalTermianlId",
            interchange: "pages.transactions.interchange",
            lifeCycleId: "pages.transactions.lifeCycleId",
            paid: "pages.transactions.paid",
            terminalId: "pages.transactions.terminalId",
            arn: "pages.transactions.arn",
            fees: "pages.transactions.fees",
            originalCurrency: "pages.transactions.originalCurrency",
            partnerId: "pages.transactions.partnerId",
            registrationDate: "pages.transactions.registrationDate",
            transactionType: "pages.transactions.transactionType",
            merchantFee: "pages.transactions.merchantFee",
            schemeFeePercent: "pages.transactions.schemeFeePercent",
            schemeFeeBase: "pages.transactions.schemeFeeBase",
            schemeFeeFixed: "pages.transactions.schemeFeeFixed",
            authorizationCode: "pages.transactions.authorizationCode",
            cardEntryModeType: "pages.transactions.cardEntryModeType",
            authenticationMethodType: "pages.transactions.authenticationMethodType",
            authorizationMethodType: "pages.transactions.authorizationMethodType",
            isReversal: "pages.transactions.isReversal",
            refundStatus: "pages.transactions.refundStatus",
            refundAmount: "pages.transactions.refundAmount",
            viewAuthorization: "pages.transaction.viewAuthorization",
            details: {
                title: "pages.transactions.details.title",
                refund: "pages.transactions.details.refund",
                printDetails: "pages.transactions.details.printDetails",
                print: "pages.transactions.details.print",
                refundReportError: "pages.transactions.details.refundReportError",
                transactionReceiptError: "pages.transactions.details.transactionReceiptError",
            },
            additionalData: "pages.transactions.additionalData",
            transactionRefundBreakdown: {
                title: "pages.transactions.transactionRefundBreakdown.title",
                viewRefunds: "pages.transactions.transactionRefundBreakdown.viewRefunds",
                refundedOf: "pages.transactions.transactionRefundBreakdown.refundedOf",
                noRecords: "pages.transactions.transactionRefundBreakdown.noRecords",
                originalTransaction: "pages.transactions.transactionRefundBreakdown.originalTransaction",
                user: "pages.transactions.transactionRefundBreakdown.user",
                date: "pages.transactions.transactionRefundBreakdown.date",
                amount: "pages.transactions.transactionRefundBreakdown.amount",
            },
        },
        [PageTypeEnum.Terminals]: {
            title: "pages.terminals.title",
            merchantName: "pages.terminals.merchantName",
            terminalId: "pages.terminals.terminalId",
            dbaName: "pages.terminals.dbaName",
            city: "pages.terminals.city",
            country: "pages.terminals.country",
            mcc: "pages.terminals.mcc",
            status: "pages.terminals.status",
            partnerName: "pages.terminals.partnerName",
            agreementNumber: "pages.terminals.agreementNumber",
            generalInfo: "pages.terminals.generalInfo",
            terminalFunctionalities: "pages.terminals.terminalFunctionalities",
            dba: "pages.terminals.dba",
            expectedStoreTurnoverInIsk: "pages.terminals.expectedStoreTurnoverInIsk",
            create:{
                requestTerminal: "pages.terminals.create.requestTerminal",
                successMsg: "pages.terminals.create.successMsg",
                confirm: "pages.terminals.create.confirm",
                title: "pages.terminals.create.title",
                agreementNumber: "pages.terminals.create.agreementNumber",                
                store: "pages.terminals.create.store",
                typeOfBusiness: "pages.terminals.create.typeOfBusiness",
                printer: "pages.terminals.create.printer",
                noPrinter: "pages.terminals.create.noPrinter",
                ecrIntegrated: "pages.terminals.create.ecrIntegrated",
                standalone: "pages.terminals.create.standalone",
                quantityOfTerminals: "pages.terminals.create.quantityOfTerminals",
                wifi: "pages.terminals.create.wifi",
                fourG: "pages.terminals.create.fourG",
                ecrSystem: "pages.terminals.create.ecrSystem",
                name: "pages.terminals.create.name",
                streetAndNumber: "pages.terminals.create.streetAndNumber",
                city: "pages.terminals.create.city",
                postalCode: "pages.terminals.create.postalCode",
                country: "pages.terminals.create.country",
                averageDeliveryInDays: "pages.terminals.create.averageDeliveryInDays",
                annualVolumeCardPresent: "pages.terminals.create.annualVolumeCardPresent",
                averageMonthlyVolume: "pages.terminals.create.averageMonthlyVolume",
                maxTransactionAmount: "pages.terminals.create.maxTransactionAmount",
                anualVolumeMoto: "pages.terminals.create.anualVolumeMoto",
                averageTransactionAmount: "pages.terminals.create.averageTransactionAmount",
                annualVolumeTotal: "pages.terminals.create.annualVolumeTotal",
                averageMonthlyNumberOfTransactions: "pages.terminals.create.averageMonthlyNumberOfTransactions", 
                newAgreement: "pages.terminals.create.newAgreement",                
                newStore: "pages.terminals.create.newStore", 
                newMccLabel: "pages.terminals.create.newMccLabel",                             
            },
            error:{
                quantityOfTerminalsMustBeLarger: "pages.terminals.error.quantityOfTerminalsMustBeLarger",
                quantityOfTerminalsRequired: "pages.terminals.error.quantityOfTerminalsRequired",
                ecrSystemRequired: "pages.terminals.error.ecrSystemRequired",
                agreementNumberRequired: "pages.terminals.error.agreementNumberRequired",
                storeIsRequired: "pages.terminals.error.storeIsRequired",
                typeOfBusinessIsRequired: "pages.terminals.error.typeOfBusinessIsRequired",
                nameIsRequired: "pages.terminals.error.nameIsRequired",
                streetAndNumberIsRequired: "pages.terminals.error.streetAndNumberIsRequired",
                cityIsRequired: "pages.terminals.error.cityIsRequired",
                postalCodeIsRequired: "pages.terminals.error.postalCodeIsRequired",
                countryIsRequired: "pages.terminals.error.countryIsRequired",
                averageDeliveryInDaysIsRequired: "pages.terminals.error.averageDeliveryInDaysIsRequired",
                annualVolumeCardPresentIsRequired: "pages.terminals.error.annualVolumeCardPresentIsRequired",
                averageMonthlyVolumeIsRequired: "pages.terminals.error.averageMonthlyVolumeIsRequired",
                maxTransactionAmountIsRequired: "pages.terminals.error.maxTransactionAmountIsRequired",
                annualVolumeMotoIsRequired: "pages.terminals.error.annualVolumeMotoIsRequired",
                averageTransactionAmountIsRequired: "pages.terminals.error.averageTransactionAmountIsRequired",
                annualVolumeTotalIsRequired: "pages.terminals.error.annualVolumeTotalIsRequired",
                averageMonthlyNumberOfTransactionsIsRequired: "pages.terminals.error.averageMonthlyNumberOfTransactionsIsRequired",
                fetchError: "pages.terminals.error.fetchError",
                submitError: "pages.terminals.error.submitError",
            }           
        },
        [PageTypeEnum.Employees]: {
            title: "pages.employees.title",
            permissions: "pages.employees.permissions",
            apiKey: "pages.employees.apiKey",
            table: {
                nameHeaderLabel: "pages.employees.table.nameHeaderLabel",
                usernameHeaderLabel: "pages.employees.table.usernameHeaderLabel",
                statusHeaderLabel: "pages.employees.table.statusHeaderLabel",
                contractsHeaderLabel: "pages.employees.table.contractsHeaderLabel",
                statusActiveLabel: "pages.employees.table.statusActiveLabel",
                statusInactiveLabel: "pages.employees.table.statusInactiveLabel",
                employeeRoleLabel: "pages.employees.table.employeeRoleLabel",
            },
            create: {
                buttonText: "pages.employees.create.buttonText",
                title: "pages.employees.create.title",
                permissions: "pages.employees.create.permissions",
                permissionsPage: "pages.employees.create.permissionsPage",
                confirm: "pages.employees.create.confirm",
                close: "pages.employees.create.close",
                formEmailInputLabel: "pages.employees.create.formEmailInputLabel",
                formContractInputLabel: "pages.employees.create.formContractInputLabel",
                formButtonCaption: "pages.employees.create.formButtonCaption",
                formEmailValidationMsg: "pages.employees.create.formEmailValidationMsg",
                formUsernameAlreadyHasAccessValidationMsg:
                    "pages.employees.create.formUsernameAlreadyHasAccessValidationMsg",
                successMsg: "pages.employees.create.successMsg",
            },
            edit: {
                title: "pages.employees.edit.title",
                permissions: "pages.employees.edit.permissions",
                contracts: "pages.employees.edit.contracts",
                permissionsPage: "pages.employees.edit.permissionsPage",
                confirm: "pages.employees.edit.confirm",
                deactivate: "pages.employees.edit.deactivate",
                close: "pages.employees.edit.close",
                formContractInputLabel: "pages.employees.edit.formContractInputLabel",
                successMsg: "pages.employees.edit.successMsg",
                errorMsg: "pages.employees.edit.errorMsg",
                errorApiKeyMsg: "pages.employees.edit.errorApiKeyMsg",
                successDeactivateMsg: "pages.employees.edit.successDeactivateMsg",
                formRoleLabel: "pages.employees.edit.formRoleLabel",
                formAdminLabel: "pages.employees.edit.formAdminLabel",
                formUserLabel: "pages.employees.edit.formUserLabel",
                formAdminEmployeeHasAccessToAllContracts:
                    "pages.employees.edit.formAdminEmployeeHasAccessToAllContracts",
                formAdminEmployeeHasAllPermissions: "pages.employees.edit.formAdminEmployeeHasAllPermissions",
            },
            details: {
                title: "pages.employees.details.title",
                edit: "pages.employees.details.edit",
                employeeId: "pages.employees.details.employeeId",
                username: "pages.employees.details.username",
                name: "pages.employees.details.name",
                createdTime: "pages.employees.details.createdTime",
                archivedTime: "pages.employees.details.archivedTime",
                verificationTime: "pages.employees.details.verificationTime",
                status: "pages.employees.details.status",
                lastLoginDate: "pages.employees.details.lastLoginDate",
                contracts: "pages.employees.details.contracts",
                languageType: "pages.employees.details.languageType",
                showApiKey: "pages.employees.details.showApiKey",
            },
        },
        [PageTypeEnum.Disputes]: {
            title: "pages.disputes.title",
            purchaseDate: "pages.disputes.purchaseDate",
            agreement: "pages.disputes.agreement",
            cardNumber: "pages.disputes.cardNumber",
            registrationDate: "pages.disputes.registrationDate",
            gross: "pages.disputes.gross",
            currency: "pages.disputes.currency",
            settlementNumber: "pages.disputes.settlementNumber",
            arn: "pages.disputes.arn",
            uploadDispute: "pages.disputes.uploadDispute",
            comment: "pages.disputes.comment",
            emailAddress: "pages.disputes.emailAddress",
            cardType: "pages.disputes.cardType",
            transactionType: "pages.disputes.transactionType",
            disputeType: "pages.disputes.disputeType",
            transactionNumber: "pages.disputes.transactionNumber",
            batchNumber: "pages.disputes.batchNumber",
            isChargeback: "pages.disputes.isChargeback",
            isFinancial: "pages.disputes.isFinancial",
            isRepresentment: "pages.disputes.isRepresentment",
            isReversal: "pages.disputes.isReversal",
            uploadDisputeSuccess: "pages.disputes.uploadDisputeSuccess",
            uploadDisputeError: "pages.disputes.uploadDisputeError",
            dateFrom: "pages.disputes.dateFrom",
            dateTo: "pages.disputes.dateTo",
            emailInvalid: "pages.disputes.emailInvalid",
            confirmationEmailWillBeSent: "pages.disputes.confirmationEmailWillBeSent",
            fileUploadErrorTitle: "pages.disputes.fileUploadErrorTitle",
            fileUploadErrorDefault: "pages.disputes.fileUploadErrorDefault",
            submitLater: "pages.disputes.submitLater",
            confirmSubmit: "pages.disputes.confirmSubmit",
            confirmSubmitTitle: "pages.disputes.confirmSubmitTitle",
            submit: "pages.disputes.submit",
            confirmRemoveTitle: "pages.disputes.confirmRemoveTitle",
            confirmRemoveText: "pages.disputes.confirmRemoveText",
            confirmRemoveButton: "pages.disputes.confirmRemoveButton",
            removeDocumentsError: "pages.disputes.removeDocumentsError",
            removeDocumentsSuccess: "pages.disputes.removeDocumentsSuccess",
            acceptDisputeSuccess: "pages.disputes.acceptDisputeSuccess",
            acceptDisputeError: "pages.disputes.acceptDisputeError",
            removeDocuments: "pages.disputes.removeDocuments",
            retry: "pages.disputes.retry",
            remove: "pages.disputes.remove",
            fileLimitations: "pages.disputes.fileLimitations",
            fileSizeError: "pages.disputes.fileSizeError",
            commentRequired: "pages.disputes.commentRequired",
            emailRequired: "pages.disputes.emailRequired",
            details: {
                title: "pages.disputes.details.title",
                edit: "pages.disputes.details.edit",
            },
        },
        [PageTypeEnum.NotFound]: {
            title: "pages.notFound.title",
            description: "pages.notFound.description",
            link: "pages.notFound.link",
        },
        [PageTypeEnum.None]: {
            title: "pages.none.title",
        },
        [PageTypeEnum.DisputeNew]: {
            title: "pages.disputeNew.title",
            currency: "pages.disputeNew.currency",
            disputeType: "pages.disputeNew.disputeType",
            filter: "pages.disputeNew.filter",
            columnChooser: "pages.disputeNew.columnChooser",
            reasonCode: "pages.disputeNew.reasonCode",
            reasonCodeValue: "pages.disputeNew.reasonCodeValue",
            reasonCodeCategory: "pages.disputeNew.reasonCodeCategory",
            status: "pages.disputeNew.status",
            settlementAmount: "pages.disputeNew.settlementAmount",
            transactionAmount: "pages.disputeNew.transactionAmount",
            update: "pages.disputeNew.update",
            date: "pages.disputeNew.date",
            type: "pages.disputeNew.type",
            caseNumber: "pages.disputeNew.caseNumber",
        },
        settings: {
            title: "pages.settings.title",
            generalTab: "pages.settings.generalTab",
            emailsAndNotificationsTab: "pages.settings.emailsAndNotifications",
            changePasswordTab: "pages.settings.changePasswordTab",
            accountAndSecurity: {
                title: "pages.settings.accountAndSecurity.title",
                details: "pages.settings.accountAndSecurity.details",
                changePasswordTitle: "pages.settings.accountAndSecurity.changePasswordTitle",
                username: "pages.settings.accountAndSecurity.username",
            },
            general: {
                defaultLanguage: "pages.settings.general.defaultLanguage",
            },
            changePassword: {
                oldPassword: "pages.settings.changePassword.oldPassword",
                newPassword: "pages.settings.changePassword.newPassword",
                repeatNewPassword: "pages.settings.changePassword.repeatNewPassword",
                saveNewPassword: "pages.settings.changePassword.saveNewPassword",
                confirmNewPassword: "pages.settings.changePassword.confirmNewPassword",
                currentPassword: "pages.settings.changePassword.currentPassword",
            },
            emailsAndNotifications: {
                statementsTitle: "pages.settings.emailsAndNotifications.statementsTitle",
                statementsRadioTitle: "pages.settings.emailsAndNotifications.statementsRadioTitle",
                statementsRadioPdfEmail: "pages.settings.emailsAndNotifications.statementsRadioPdfEmail",
                statementsRadioNotificationEmail:
                    "pages.settings.emailsAndNotifications.statementsRadioNotificationEmail",
                statementsRadioNoEmail: "pages.settings.emailsAndNotifications.statementsRadioNoEmail",
                radioChange: {
                    statementsRadioError: "pages.settings.emailsAndNotifications.radioChange.error",
                    statementsRadioSuccess: "pages.settings.emailsAndNotifications.radioChange.success",
                    disputeRadioError: "pages.settings.emailsAndNotifications.radioChange.disputeError",
                    disputeRadioSuccess: "pages.settings.emailsAndNotifications.radioChange.disputeSuccess",
                },
                disputeTitle: "pages.settings.emailsAndNotifications.disputeTitle",
                disputeRadioTitle: "pages.settings.emailsAndNotifications.disputeRadioTitle",
                disputeRadioNotificationEmail: "pages.settings.emailsAndNotifications.disputeRadioNotificationEmail",
                disputeRadioNoEmail: "pages.settings.emailsAndNotifications.disputeRadioNoEmail",
            },
        },
        terms: {
            title: "pages.terms.title",
            descriptionFirst: "pages.terms.descriptionFirst",
            descriptionLink: "pages.terms.descriptionLink",
            descriptionSecond: "pages.terms.descriptionSecond",
            link: "pages.terms.link",
            conditions: "pages.terms.conditions",
            acceptTerms: "pages.terms.acceptTerms",
            back: "pages.terms.back",

            userMessageAcceptTerms: "pages.terms.userMessageAcceptTerms",
            selectMerchant: "pages.terms.selectMerchant",
            newTermsAvailable: {
                first: "pages.terms.newTermsAvailable.first",
                second: "pages.terms.newTermsAvailable.second",
                third: "pages.terms.newTermsAvailable.third",
            },
        },
    },
    export: {
        confirm: "export.confirm",
        title: "export.title",
        close: "export.close",
        exportType: "export.exportType",
        exportTypeCSV: "export.exportTypeCSV",
        exportTypeXLSX: "export.exportTypeXLSX",
        exportTypePDF: "export.exportTypePDF",
        exportAllColumns: "export.exportAllColumns",
        exportVisibleColumns: "export.exportVisibleColumns",
        exportVisibleColumn: "export.exportVisibleColumn",
        exportColumn: "export.exportColumn",
        exportSuccess: "export.exportSuccess",
        exportError: "export.exportError",
        exportToManyRows: "export.exportToManyRows",
    },
    rowSelection: {
        clearAll: "rowSelection.clearAll",
        selectedRows: "rowSelection.selectedRows",
    },
    input: {
        passwordShowCaption: "input.passwordShowCaption",
        passwordHideCaption: "input.passwordHideCaption",
        passwordStrengthBarLabel: "input.passwordStrengthBarLabel",
        passwordStrengthBad: "input.passwordStrengthBad",
        passwordStrengthWeak: "input.passwordStrengthWeak",
        passwordStrengthPassable: "input.passwordStrengthPassable",
        passwordStrengthGood: "input.passwordStrengthGood",
        passwordStrengthVeryGood: "input.passwordStrengthVeryGood",
        add: "input.add",
    },
    login: {
        backActionLabel: "login.backActionLabel",
        title: "login.title",
        formTitle: "login.formTitle",
        formImpersonateTitle: "login.formImpersonateTitle",
        formEmailInputLabel: "login.formEmailInputLabel",
        formEmailValidationMsg: "login.formEmailValidationMsg",
        formPasswordInputLabel: "login.formPasswordInputLabel",
        formButtonCaption: "login.formButtonCaption",
        forgotPasswordLink: "login.forgotPasswordLink",
        loginFailed: "login.loginFailed",
    },
    forgotPassword: {
        title: "forgotPassword.title",
        description: "forgotPassword.description",
        formEmailInputLabel: "forgotPassword.formEmailInputLabel",
        formEmailValidationMsg: "forgotPassword.formEmailValidationMsg",
        formButtonCaption: "forgotPassword.formButtonCaption",
        backButtonCaption: "forgotPassword.backButtonCaption",
        formSubmittedTitle: "forgotPassword.formSubmittedTitle",
        formSubmittedDescription: "forgotPassword.formSubmittedDescription",
        formSubmittedBackButtonCaption: "forgotPassword.formSubmittedBackButtonCaption",
    },
    resetPassword: {
        title: "resetPassword.title",
        formPasswordInputLabel: "resetPassword.formPasswordInputLabel",
        formPasswordValidationMsg: "resetPassword.formPasswordValidationMsg",
        formConfirmPasswordInputLabel: "resetPassword.formConfirmPasswordInputLabel",
        formConfirmPasswordValidationMsg: "resetPassword.formConfirmPasswordValidationMsg",
        formButtonCaption: "resetPassword.formButtonCaption",
        formSubmittedTitle: "resetPassword.formSubmittedTitle",
        formSubmittedDescription: "resetPassword.formSubmittedDescription",
        formSubmittedBackButtonCaption: "resetPassword.formSubmittedBackButtonCaption",
    },
    confirmAccount: {
        title: "confirmAccount.title",
        subTitle: "confirmAccount.subTitle",
        formDisplayUsernameInputLabel: "confirmAccount.formDisplayUsernameInputLabel",
        formPasswordInputLabel: "confirmAccount.formPasswordInputLabel",
        formPasswordValidationMsg: "confirmAccount.formPasswordValidationMsg",
        formConfirmPasswordInputLabel: "confirmAccount.formConfirmPasswordInputLabel",
        formConfirmPasswordValidationMsg: "confirmAccount.formConfirmPasswordValidationMsg",
        formButtonCaption: "confirmAccount.formButtonCaption",
        formSubmittedTitle: "confirmAccount.formSubmittedTitle",
        formSubmittedDescription: "confirmAccount.formSubmittedDescription",
        formSubmittedBackButtonCaption: "confirmAccount.formSubmittedBackButtonCaption",
        contactServiceDesk: "confirmAccount.contactServiceDesk",
    },
    multiselect: {
        selected: "multiselect.selected",
        options: "multiselect.options",
        selectAll: "multiselect.selectAll",
        clearAll: "multiselect.clearAll",
    },

    versionCheck: {
        text: "versionCheck.text",
        button: "versionCheck.button",
    },

    kyc: {
        backActionLabel: "kyc.backActionLabel",
        title: "kyc.title",
        documentTitle: "kyc.documentTitle",
        formTitle: "kyc.formTitle",
        formPhoneNumberInputLabel: "kyc.formPhoneNumberInputLabel",
        formButtonCaption: "kyc.formButtonCaption",
        somethingWentWrong: "kyc.somethingWentWrong",
    },
    kycLegalEntity: {
        backActionLabel: "kycLegalEntity.backActionLabel",
        title: "kycLegalEntity.title",
        subTitle: "kycLegalEntity.subTitle",
        empty: "kycLegalEntity.empty",
    },
    kycSurvey: {
        backActionLabel: "kycSurvey.backActionLabel",
        title: "kycSurvey.title",
        changeText: "kycSurvey.changeText",
        changeButton: "kycSurvey.changeButton",
        formButtonCaption: "kycSurvey.formButtonCaption",
        fieldError: "kycSurvey.fieldError",
        reviewAnswer: "kycSurvey.reviewAnswer",
        fetchQuestionarieError: "kycSurvey.fetchQuestionarieError",
        genericError: "kycSurvey.genericError",
        beneficialOwner: {
            title: "kycSurvey.beneficialOwner.title",
            fullName: "kycSurvey.beneficialOwner.fullName",
            ssn: "kycSurvey.beneficialOwner.ssn",
            phone: "kycSurvey.beneficialOwner.phone",
            shares: "kycSurvey.beneficialOwner.shares",
            addPerson: "kycSurvey.beneficialOwner.addPerson",
        },
        legalBinder: {
            title: "kycSurvey.legalBinder.title",
            fullName: "kycSurvey.legalBinder.fullName",
            ssn: "kycSurvey.legalBinder.ssn",
            phone: "kycSurvey.legalBinder.phone",
            position: "kycSurvey.legalBinder.position",
            addPerson: "kycSurvey.legalBinder.addPerson",
            positions: {
                boardMember: "kycSurvey.legalBinder.positions.boardMember",
                ceo: "kycSurvey.legalBinder.positions.ceo",
                chairmanOfTheBoard: "kycSurvey.legalBinder.positions.chairmanOfTheBoard",
                comanager: "kycSurvey.legalBinder.positions.comanager",
                other: "kycSurvey.legalBinder.positions.other",
                powerOfAttorney: "kycSurvey.legalBinder.positions.powerOfAttorney",
            },
        },
    },
    kycSuccess: {
        backActionLabel: "kycSuccess.backActionLabel",
        title: "kycSuccess.title",
        subTitle: "kycSuccess.titsubTitlele",
        buttonCaption: "kycSuccess.buttonCaption",
    },
    activityTracker: {
        somethingWentWrong: "activityTracker.somethingWentWrong",
        loggedOutInactivtiy: "activityTracker.loggedOutInactivtiy",
    },
    networkStatusIndicator: {
        online: "networkStatusIndicator.online",
        offline: "networkStatusIndicator.offline",
    },
};
