import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    IChangeDefaultLanguageRequest,
    IChangePasswordRequest,
    ISetMerchantRequest,
    IChangeStatementNotificationTypeRequest,
    IChangeDisputeNotificationTypeRequest,
} from "redux/models/userModels";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { IPageSettingsRequest } from "utils/models";
import { setUserLocalStorageData } from "utils/storageActions";

const baseUsersRoute = "/api/users";

export const userGetMerchants = createAsyncThunk("user/getMerchants", async () => {
    return await executeAxiosRequestWithRefresh({
        url: "/api/merchants/available-merchants",
        method: "GET",
    });
});

export const userGetSettings = createAsyncThunk("user/getSettings", async () => {
    return await executeAxiosRequestWithRefresh({
        url: `${baseUsersRoute}/settings`,
        method: "GET",
    });
});

export const userSetMerchant = createAsyncThunk<any, ISetMerchantRequest>("user/setMerchant", async request => {
    const response = await executeAxiosRequestWithRefresh({
        url: `${baseUsersRoute}/set-merchant`,
        method: "POST",
        data: {
            merchantId: request.merchantId,
        },
    });

    // this endpoint will return new token and we need to save it to local storage
    setUserLocalStorageData(response.data);

    return response.data;
});

export const userChangeDefaultLanguage = createAsyncThunk<any, IChangeDefaultLanguageRequest>(
    "user/changeDefaultLanguage",
    async request => {
        await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/change-default-language`,
            method: "POST",
            data: {
                language: request.languageId,
            },
        });

        // Response just returns OK, so we send request language data to update the store.
        return request;
    }
);

export const userChangePassword = createAsyncThunk<any, IChangePasswordRequest>(
    "user/changePassword",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/change-password`,
            method: "POST",
            data: {
                existingPassword: request.existingPassword,
                newPassword: request.newPassword,
            },
        });

        return response.data;
    }
);

export const userSelectStatementNotificationType = createAsyncThunk<any, IChangeStatementNotificationTypeRequest>(
    "user/toggleStatementEmail",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/change-statement-notification-type`,
            method: "POST",
            data: request,
        });

        return request;
    }
);

export const userSelectDisputeNotificationType = createAsyncThunk<any, IChangeDisputeNotificationTypeRequest>(
    "user/toggleDisputeEmail",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/change-dispute-notification-type`,
            method: "POST",
            data: request,
        });

        return request;
    }
);

export const updateUserSettings = createAsyncThunk<any, IPageSettingsRequest>(
    "user/update-user-column-selection",
    async request => {
        const data = localStorage.getItem(request.pageType);

        const response = await executeAxiosRequestWithRefresh({
            url: `${baseUsersRoute}/update-user-column-selection`,
            method: "POST",
            data: {
                pageTypeKey: `${request.pageType}`,
                userSettings: data,
            },
        });

        return request;
    }
);
