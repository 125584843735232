import { useSelector } from "react-redux";
import { ModalBaseProps } from "redux/models/modalModels";
import { RootState } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import "./baseModalContainer.scss";
import CreateEmployeeModal from "./createEmployeeModal/createEmployeeModal";
import DeleteModal from "./deleteModal/deleteModal";
import EditEmployeeModal from "./editEmployeeModal/editEmployeeModal";
import ExportModal from "./exportModal/exportModal";
import SetSalesTargetModal from "./setSalesTargetModal/setSalesTargetModal";
import ReportDetailsModal from "./reportDetailsModal/reportDetailsModal";
import SpecialMenuFilterModal from "./specialMenuModal/specialMenuFilterModal/specialMenuFilterModal";
import SpecialMenuColumnModal from "./specialMenuModal/specialMenuColumnModal/specialMenuColumnModal";
import UploadDisputeModal from "./uploadModal/uploadDisputeModal";
import FeedbackFormModal from "./feedbackFormModal/feedbackFormModal";
import RefundTransactionModal from "./refundTransactionModal/refundTransactionModal";
import BaseModal from "components/baseModal/baseModal";
import DisputeDetailsModal from "containers/modalContainers/disputeDetails/disputeDetails";
import RequestTerminalModal from "./requestTerminal/requestTerminalModal";

const modalMapping: { [modalType in ModalTypeEnum]: Function } = {
    [ModalTypeEnum.RequestTerminal]: RequestTerminalModal,
    [ModalTypeEnum.DisputeDetails]: DisputeDetailsModal,
    [ModalTypeEnum.UploadDisputeModal]: UploadDisputeModal,
    [ModalTypeEnum.SetSalesTargetModal]: SetSalesTargetModal,
    [ModalTypeEnum.ExportModal]: ExportModal,
    [ModalTypeEnum.DeleteModal]: DeleteModal,
    [ModalTypeEnum.CreateEmployee]: CreateEmployeeModal,
    [ModalTypeEnum.EditEmployee]: EditEmployeeModal,
    [ModalTypeEnum.DetailsModal]: ReportDetailsModal,
    [ModalTypeEnum.FeedbackFormModal]: FeedbackFormModal,
    [ModalTypeEnum.SpecialMenuColumnModal]: SpecialMenuColumnModal,
    [ModalTypeEnum.SpecialMenuFilterModal]: SpecialMenuFilterModal,
    [ModalTypeEnum.RefundTransaction]: RefundTransactionModal,
    [ModalTypeEnum.Confirm]: BaseModal,
    [ModalTypeEnum.None]: () => {
        <></>;
    },
};
function BaseModalContainer() {
    const { modalDescriptors } = useSelector((state: RootState) => state.modal);

    if (modalDescriptors.length === 0) {
        return <></>;
    }

    return (
        <>
            {modalDescriptors
                .filter(x => x.isOpened)
                .map((value: ModalBaseProps) => {
                    const Modal = modalMapping[value.modalType];
                    return <Modal key={value.modalType} {...value.props} data={value.data} />;
                })}
        </>
    );
}

export default BaseModalContainer;
