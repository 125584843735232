import { ISettlementsFilter } from "pages/settlements/api/settlementsModels";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDrillFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { nameof } from "ts-simple-nameof";
import { PageTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import { PATHS } from "utils/routing/paths";
import "./agreementsQuickLinks.scss";

const AgreementsQuickLinks = () => {
    const { t } = useTranslation();
    const pageType = useSelector((state: RootState) => state.report.pageType);
    const activeRow = useSelector((state: RootState) => state.report.activeRow);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    if (pageType != PageTypeEnum.Agreements || !activeRow) {
        return <></>;
    }

    const onRedirectClickWithDrill = async () => {
        await dispatch(
            setDrillFilters([
                {
                    field: nameof<ISettlementsFilter>(x => x.agreementNumber),
                    value: activeRow.agreementNumber,
                },
            ])
        );

        navigate(PATHS.Portal.Settlements);
    };
    return (
        <div className="agreements-quick-link" onClick={onRedirectClickWithDrill}>
            {`${t(StringResources.pages.agreements.viewSettlements)}`}
        </div>
    );
};

export default AgreementsQuickLinks;
