import { Trans, useTranslation } from "react-i18next";
import { ModalActionButtonTypeEnum, ModalTypeEnum } from "utils/enums";
import { Form } from "react-bootstrap";
import { StringResources } from "utils/language/languageResource";
import "./refundTransactionModal.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import BaseButton from "components/baseButton/baseButton";
import { RootState, useAppDispatch } from "redux/store";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import classNames from "classnames";
import { IGetTransactionsResponseListData } from "pages/transactions/api/transactionModels";
import { useSelector } from "react-redux";
import { acceptTransactionRefund, startTransactionRefund } from "redux/actions/transactionActions";
import { IStartRefundTransactionRequest } from "redux/models/transactionModels";
import { PayfacAxiosRequestConfig } from "api/requestModels";
import { Currency, formatter } from "utils/formatter";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { toast } from "react-toastify";
import { useModalManagement } from "utils/customHooks";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import { getDownloadPdfFileMetadata } from "utils/helperFunctions";
import { getContentDispositionFilenameUtf8 } from "utils/helperFunctions";
import { saveFile } from "utils/helperFunctions";
import BaseCurrencyInputATM from "components/baseCurrencyInput/baseCurrencyInputATM";

interface IRefundTransactionForm {
    refundAmount: number;
    downloadRefundReceipt: boolean;
}

interface IRefundTransactionDetails {
    originalAmount: number;
    refundedAmount: number;
    currency: Currency;
}

const RefundTransactionModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const modalManagement = useModalManagement();
    const [refundTransactionDetails, setRefundTransactionDetails] = useState<IRefundTransactionDetails>();
    const activeRowData: IGetTransactionsResponseListData = useSelector((state: RootState) => state.report.activeRow);

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            refundAmount: 0,
            downloadRefundReceipt: false,
        },
        validationSchema: Yup.object({
            refundAmount: Yup.number()
                .min(1, ({ min }) => t(StringResources.modal.refundTransaction.formRefundAmountMin, { min }))
                .max(
                    refundTransactionDetails
                        ? refundTransactionDetails.originalAmount - refundTransactionDetails.refundedAmount
                        : 1,
                    ({ max }) => t(StringResources.modal.refundTransaction.formRefundAmountMax, { max })
                )
                .required(t(StringResources.modal.refundTransaction.formRefundAmountRequired)),
        }),
        onSubmit: async (value: IRefundTransactionForm) => {
            setErrorMessage("");

            const model: IStartRefundTransactionRequest = {
                transactionId: activeRowData.transactionId,
                refundAmount: value.refundAmount,
            };

            try {
                const response = await dispatch(startTransactionRefund(model)).unwrap();

                const amount = formatter(response.refundAmount, refundTransactionDetails!.currency, {
                    formatOptions: "Symbol",
                    specialRules: ["NoRounding"],
                });

                const maxAmount = formatter(
                    refundTransactionDetails!.originalAmount + refundTransactionDetails!.refundedAmount,
                    refundTransactionDetails!.currency
                );

                const modalData = await modalManagement.openModal({
                    modalType: ModalTypeEnum.Confirm,
                    props: {
                        title: t(StringResources.modal.refundTransaction.refund),
                        text: t(StringResources.modal.refundTransaction.confirmRefundMessage, {
                            amount,
                            maxAmount,
                        }),
                        modalType: ModalTypeEnum.Confirm,
                        modalActionButtonType: ModalActionButtonTypeEnum.Confirm,
                        actionButtonText: t(StringResources.modal.confirm),
                        closeButtonText: t(StringResources.modal.cancel),
                    },
                });

                if (modalData !== null) {
                    await dispatch(acceptTransactionRefund({ refundRequestId: response.refundRequestId })).unwrap();

                    if (formValidation.values["downloadRefundReceipt"]) {
                        const config: PayfacAxiosRequestConfig = {
                            url: "/api/transactions/refund-receipt",
                            method: "GET",
                            responseType: "blob",
                            params: {
                                refundRequestId: response.refundRequestId,
                            },
                        };

                        try {
                            const response = await executeAxiosRequestWithRefresh(config);

                            const metadata = getDownloadPdfFileMetadata();
                            var filename = getContentDispositionFilenameUtf8(response.headers);
                            saveFile(response.data, filename, metadata.contentType);
                            toast.success(t(StringResources.modal.refundTransaction.downloadSuccess).toString());
                        } catch (error: any) {
                            setErrorMessage(error?.message);
                            toast.error(t(StringResources.modal.refundTransaction.downloadError).toString());
                        }
                    }

                    dispatch(confirmModal({ modalType: ModalTypeEnum.RefundTransaction }));
                    toast.success(t(StringResources.modal.refundTransaction.success).toString());
                }
            } catch (error: any) {
                toast.error(t(StringResources.modal.refundTransaction.error).toString());
                setErrorMessage(error?.message);
            }
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.RefundTransaction }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    const fetchDetails = async () => {
        const config: PayfacAxiosRequestConfig = {
            url: "/api/transactions/refund-details",
            disableErrorToast: true,
            method: "GET",
            params: {
                transactionId: activeRowData.transactionId,
            },
        };

        const response = await executeAxiosRequestWithRefresh(config);
        setRefundTransactionDetails(response.data);
    };

    useEffect(() => {
        fetchDetails();
    }, []);

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.modal.refundTransaction.title)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="refund-transaction__modal-body">
                    <div className="refund-transaction">
                        <div className="refund-transaction__item-label">
                            {`${t(StringResources.modal.refundTransaction.message)}`}
                        </div>
                        <div className="refund-transaction__item">
                            <div className="refund-transaction__item-input">
                                <BaseCurrencyInputATM
                                    label={`${t(StringResources.modal.refundTransaction.formRefundAmount)}`}
                                    name={"refundAmount"}
                                    value={formValidation.values["refundAmount"]}
                                    onValueChange={(value: number) => {
                                        formValidation.setFieldValue("refundAmount", value);
                                    }}
                                    invalid={
                                        formValidation.touched["refundAmount"] && formValidation.errors["refundAmount"]
                                    }
                                    onBlur={formValidation.handleBlur}
                                    currency={activeRowData.currencyId!}
                                />
                            </div>
                        </div>

                        {refundTransactionDetails && (
                            <div>
                                <>
                                    {t(StringResources.modal.refundTransaction.maxAmountForRefund, {
                                        maxAmount: formatter(
                                            refundTransactionDetails.originalAmount +
                                                refundTransactionDetails.refundedAmount,
                                            refundTransactionDetails.currency
                                        ),
                                    })}{" "}
                                </>
                            </div>
                        )}

                        <div>
                            <BaseCheckbox
                                id={"downloadRefundReceipt"}
                                checked={formValidation.values["downloadRefundReceipt"]}
                                onChange={(id, checked) => {
                                    formValidation.setFieldValue("downloadRefundReceipt", checked);
                                }}
                                label={t(StringResources.modal.refundTransaction.downloadRefundReceipt)}
                            />
                        </div>

                        {errorMessage && <p className="refund-transaction__form-error">{errorMessage}</p>}
                    </div>
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.close)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={`${t(StringResources.modal.confirm)}`}
                        styleType="solid"
                        disabled={!(formValidation.isValid && !formValidation.isValidating)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RefundTransactionModal;
