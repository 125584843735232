export const totalSubtotalMockData = [
    { name: "Total Gross", value: 100 },
    { name: "Total Net", value: 50 },
    { name: "Total Total", value: 150 },
];

export const quickLinksMockData = [
    { name: "Quick Link 1", count: 1 },
    { name: "Quick Link 2", count: 2 },
    { name: "Quick Link 3", count: 3 },
];

// mocked data for testing
export const disputesMockData = [
    {
        id: 1,
        purchaseDate: new Date("2023-01-23T22:20:10.654Z"),
        agreementId: "1",
        cardNumber: "2",
        registrationDate: "2023-01-23T14:13:10.654Z",
        grossAmount: 0,
        currency: "EUR",
        settlementNumber: "123",
        arn: "string",
        responded: false
    },
    {
        id: 2,
        purchaseDate: new Date("2023-01-22T14:13:10.654Z"),
        agreementId: "1",
        cardNumber: "2",
        registrationDate: "2023-01-22T14:13:10.654Z",
        grossAmount: 0,
        currency: "EUR",
        settlementNumber: "123",
        arn: "string",
        responded: false
    },
    {
        id: 3,
        purchaseDate: new Date("2023-01-23T14:10:10.654Z"),
        agreementId: "1",
        cardNumber: "2",
        registrationDate: "2023-01-21T14:13:10.654Z",
        grossAmount: 0,
        currency: "EUR",
        settlementNumber: "123",
        arn: "string",
        responded: false
    },
    {
        id: 4,
        purchaseDate: new Date("2023-01-23T14:10:10.654Z"),
        agreementId: "1",
        cardNumber: "2",
        registrationDate: "2023-01-23T12:13:10.654Z",
        grossAmount: 0,
        currency: "EUR",
        settlementNumber: "123",
        arn: "string",
        responded: false
    },
    {
        id: 5,
        purchaseDate: new Date("2023-01-23T14:10:10.654Z"),
        agreementId: "1",
        cardNumber: "2",
        registrationDate: "2023-03-23T14:13:10.654Z",
        grossAmount: 0,
        currency: "EUR",
        settlementNumber: "123",
        arn: "string",
        responded: false
    },

]
